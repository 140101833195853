import React, {useEffect, useState} from "react"
import {buildTitle, PageTitle} from "../components/PageTitle"
import Navbar from "../components/Navbar"
import Card from "../components/Card"
import Slider from "../components/Slider"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"

const Home = () => {
    const [espectacles, setEspectacles] = useState(null)
    const [temporada, setTemporada] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_JF_API_HOST + "/home")
            .then((response) => response.json())
            .then((json) => {
                setEspectacles(json.espectacles)
                setTemporada(json.temporada)
            })
            .catch((error) => console.error(error))
    }, [])

    return (
        <div>
            <PageTitle title={buildTitle()}/>
            <Navbar/>
            <Slider/>

            <div className='border-b border-l border-r border-blu p-4 sm:p-8 lg:p-12 flex flex-col justify-center items-center'>
                <h1 className='text-4xl lg:text-5xl text-blue font-brik uppercase'>Temporada {temporada}</h1>

                <div className='min-h-full sm:px-12 lg:px-12 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 lg:gap-12'>
                    {espectacles ? (
                        espectacles.map((project, index) => (
                            <Card
                                key={index}
                                url={project.url}
                                id={project.id}
                                imageUrl={project.image_url}
                                title={project.title}
                                text1={project.subtitle1 || ""}
                                text2={project.subtitle2 || ""}
                                button={project.cta}
                                backgroundColor={project.background_color} // Pasamos el color de fondo
                            />
                        ))
                    ) : (
                        <p style={{minHeight: "150px"}}>Carregant dades...</p>
                    )}
                </div>
            </div>

            <Newsletter/>
            <Footer/>
        </div>
    )
}

export default Home
