import React, {useState} from "react"

const Gallery = ({images}) => {
    const [selectedImage, setSelectedImage] = useState(null)

    const openImage = (image) => {
        setSelectedImage(image)
    }

    const closeImage = () => {
        setSelectedImage(null)
    }

    return (
        <div>
            {images && (<div className='py-8 px-6 border-b border-r border-l border-blue text-blue'>
                <h2 className='text-3xl tracking-wide text-blue font-brik pb-6 uppercase'>Galeria</h2>

                {/* Grid de la galería */}
                <div className='grid grid-cols-2 lg:grid-cols-4 gap-4'>
                    {images.map((image, index) => (
                        <div key={index} className='cursor-pointer aspect-video' onClick={() => openImage(image)}>
                            <img src={image} alt={`Image ${index + 1}`} className='w-full h-full object-cover rounded'/>
                        </div>
                    ))}
                </div>

                {/* Modal para imagen seleccionada */}
                {selectedImage && (
                    <div className='fixed inset-0 bg-blue bg-opacity-90 flex items-center justify-center z-50'>
                        <button onClick={closeImage} className='absolute top-4 right-4 text-gray text-2xl'>
                            X
                        </button>
                        <img src={selectedImage} alt='Selected' className='max-w-full max-h-full'/>
                    </div>
                )}
            </div>)}
        </div>
    )
}

export default Gallery
