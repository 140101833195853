import React, { useState } from "react"
import { Link } from "react-router-dom"

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEntitatOpen, setIsEntitatOpen] = useState(false) // Estado para abrir/cerrar el submenú en desktop y mobile
  const [isProgramacioOpen, setIsProgramacioOpen] = useState(false) // Estado para abrir/cerrar el submenú de "Programació"
  const [isFormacioOpen, setIsFormacioOpen] = useState(false) // Estado para abrir/cerrar el submenú de "Formació"

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const toggleEntitatMenu = () => {
    setIsEntitatOpen(!isEntitatOpen)
    setIsProgramacioOpen(false)
    setIsFormacioOpen(false)
  }

  const toggleProgramacioMenu = () => {
    setIsProgramacioOpen(!isProgramacioOpen)
    setIsEntitatOpen(false)
    setIsFormacioOpen(false)
  }

  const toggleFormacioMenu = () => {
    setIsFormacioOpen(!isFormacioOpen)
    setIsEntitatOpen(false)
    setIsProgramacioOpen(false)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
    setIsEntitatOpen(false)
    setIsProgramacioOpen(false)
    setIsFormacioOpen(false) // Cierra todo cuando el menú principal se cierra
  }

  return (
    <nav className='bg-gray border border-blue py-4 px-0 text-blue font-mori'>
      <div className='mx-6 flex justify-between items-center'>
        <Link to='/' className='text-lg lg:text-xl font-mori text-blue'>
          JOVENTUT DE LA FARÀNDULA
        </Link>

        {/* Botón menú para móviles */}
        <div className='md:hidden'>
          <button className='text-blue' onClick={toggleMenu}>
            {isMenuOpen ? "✖" : "Menú"}
          </button>
        </div>

        {/* Menú en desktop */}
        <div className='hidden md:flex space-x-4 text-sm'>
          {/* Menú PROGRAMACIÓ con clic en desktop */}
          <div className='relative'>
            <button
              className='text-white hover:underline flex items-center'
              onClick={toggleProgramacioMenu} // Abre/cierra el submenú "PROGRAMACIÓ"
            >
              PROGRAMACIÓ
            </button>

            {/* Submenú solo visible si isProgramacioOpen es true */}
            {isProgramacioOpen && (
              <div className='absolute left-0 mt-2 bg-gray text-blue shadow-lg z-50'>
                <Link
                  to='/temporada/2024-2025'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  TEMPORADA ACTUAL
                </Link>
                <Link
                  to='/programacio/temporades-anteriors'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  TEMPORADES ANTERIORS
                </Link>
                <Link
                  to='/programacio/apostes-escena'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  APOSTA EN ESCENA
                </Link>
              </div>
            )}
          </div>

          <Link to='/venda-entrades' className='text-white hover:underline'>
            ENTRADES
          </Link>
          <Link to='/com-arribar' className='text-white hover:underline'>
            COM ARRIBAR
          </Link>

          {/* Menú ENTITAT con clic en desktop */}
          <div className='relative'>
            <button
              className='text-white hover:underline flex items-center'
              onClick={toggleEntitatMenu} // Abre/cierra el submenú "ENTITAT"
            >
              ENTITAT
            </button>

            {/* Submenú solo visible si isEntitatOpen es true */}
            {isEntitatOpen && (
              <div className='absolute left-0 mt-2 bg-gray text-blue shadow-lg z-50'>
                <Link
                  to='/entitat/historia'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  HISTÒRIA
                </Link>
                <Link
                  to='/entitat/qui-som'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  QUI SOM
                </Link>
              </div>
            )}
          </div>

          {/* Menú FORMACIÓ con clic en desktop */}
          <div className='relative'>
            <button
              className='text-white hover:underline flex items-center'
              onClick={toggleFormacioMenu} // Abre/cierra el submenú "FORMACIÓ"
            >
              FORMACIÓ
            </button>

            {/* Submenú solo visible si isFormacioOpen es true */}
            {isFormacioOpen && (
              <div className='absolute left-0 mt-2 bg-gray text-blue shadow-lg z-50'>
                <Link
                  to='/formacio/teatre-infants-tti'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  TTI
                </Link>
                <Link
                  to='/formacio/teatre-adults-tta'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  TTA
                </Link>
                <Link
                  to='/formacio/aula-jove'
                  className='block px-4 py-2 hover:bg-gray-200'
                  onClick={closeMenu} // Cierra el menú al hacer clic
                >
                  AULA JOVE
                </Link>
              </div>
            )}
          </div>

          <Link to='/entitat/contacta' className='text-white hover:underline'>
            CONTACTE
          </Link>
        </div>
      </div>

      {/* Menú desplegable en móviles */}
      {isMenuOpen && (
        <div className='fixed inset-0 bg-gray border border-blue text-blue flex flex-col items-center justify-center z-50'>
          {/* Título y botón de cerrar menú en la parte superior */}
          <div className='absolute top-4 left-4 right-4 flex justify-between items-center'>
            <Link to='/' className='text-lg lg:text-xl font-mori text-blue'>
              JOVENTUT DE LA FARÀNDULA
            </Link>
            <button className='text-blue text-2xl' onClick={closeMenu}>
              ✖
            </button>
          </div>

          {/* Enlaces del menú móvil */}
          <div className='w-full text-center block px-4 py-4 text-xl border-b border-t border-blue' onClick={toggleProgramacioMenu}>
            PROGRAMACIÓ
            <span className='ml-1'>{isProgramacioOpen ? "▲" : "▼"}</span>
          </div>
          {isProgramacioOpen && (
            <div className='pl-4 border-b border-blue w-full text-center'>
              <Link to='/programacio/temporada/2024-2025' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                TEMPORADA ACTUAL
              </Link>
              <Link to='/programacio/temporades-anteriors' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                TEMPORADES ANTERIORS
              </Link>
              <Link to='/programacio/apostes-escena' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                APOSTA EN ESCENA
              </Link>
            </div>
          )}

          <Link to='/venda-entrades' className='w-full text-center block px-4 py-4 text-xl border-b border-blue' onClick={closeMenu}>
            ENTRADES
          </Link>
          <Link to='/com-arribar' className='w-full text-center block px-4 py-4 text-xl border-b border-blue' onClick={closeMenu}>
            COM ARRIBAR
          </Link>

          <div className='w-full text-center block px-4 py-4 text-xl border-b border-blue' onClick={toggleEntitatMenu}>
            ENTITAT
            <span className='ml-1'>{isEntitatOpen ? "▲" : "▼"}</span>
          </div>
          {isEntitatOpen && (
            <div className='pl-4 border-b border-blue w-full text-center'>
              <Link to='/entitat/historia' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                HISTÒRIA
              </Link>
              <Link to='/entitat/qui-som' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                QUI SOM
              </Link>
            </div>
          )}

          <div className='w-full text-center block px-4 py-4 text-xl border-b border-blue' onClick={toggleFormacioMenu}>
            FORMACIÓ
            <span className='ml-1'>{isFormacioOpen ? "▲" : "▼"}</span>
          </div>
          {isFormacioOpen && (
            <div className='pl-4 border-b border-blue w-full text-center'>
              <Link to='/formacio/teatre-infants-tti' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                TTI
              </Link>
              <Link to='formacio/teatre-adults-tta' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                TTA
              </Link>
              <Link to='/formacio/aula-jove' className='block px-4 py-2 text-lg' onClick={closeMenu}>
                AULA JOVE
              </Link>
            </div>
          )}

          <Link to='/entitat/contacta' className='w-full text-center block px-4 py-4 text-xl border-b border-blue' onClick={closeMenu}>
            CONTACTE
          </Link>
        </div>
      )}
    </nav>
  )
}

export default Navbar
