import React from "react";
import { FaFacebook, FaInstagram, FaYoutube, FaSpotify } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  return (
    <footer className="bg-gray border-r border-b border-l border-blue py-4 px-6 text-blue">
      <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        {/* Sección de redes sociales */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <p className="font-mori">VISITA'NS A LES XARXES:</p>
          <div className="flex space-x-4 mt-2">
            <a href="https://x.com/JFarandula" target="_blank" rel="noopener noreferrer">
              <FaXTwitter className="text-blue" size={20} />
            </a>
            <a href="https://www.facebook.com/JoventutdelaFarandula/" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-blue" size={20} />
            </a>
            <a href="https://www.instagram.com/jfarandula/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-blue" size={20} />
            </a>
            <a href="https://www.youtube.com/channel/UC-_3tq8wvqB1-q4CYl1ssEQ" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="text-blue" size={20} />
            </a>
            <a href="https://open.spotify.com/intl-es/artist/0Rry3CmC3I2GGa7FB8uQjz" target="_blank" rel="noopener noreferrer">
              <FaSpotify className="text-blue" size={20} />
            </a>
          </div>
        </div>

        {/* Sección de Copyright */}
        <div className="text-center md:text-right">
          <p className="font-mori">
            COPYRIGHT © 2024 JOVENTUT DE LA FARÀNDULA
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
