import React from 'react';

const Sponsors = ({ sponsors }) => {
    return (
        <div className='w-full bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
            <div className='bg-gray py-8'>
                <h2 className='text-3xl text-blue font-brik mb-6'>Patrocinadors</h2>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4'>
                    {sponsors.map((sponsor) => (
                        <img key={sponsor} src={sponsor.imageUrl} alt={sponsor.name} className='w-full h-auto object-contain max-h-14'/>
                    ))}
                    {/*{sponsors.map((sponsor, index) => (*/}
                    {/*    <a*/}
                    {/*        key={index}*/}
                    {/*        href={sponsor.link}*/}
                    {/*        target='_blank'*/}
                    {/*        rel='noopener noreferrer'*/}
                    {/*        className='block bg-gray-300 rounded-lg p-4'*/}
                    {/*    >*/}
                    {/*        <img src={sponsor.logo} alt={sponsor.name} className='w-full h-auto object-contain'/>*/}
                    {/*    </a>*/}
                    {/*))}*/}
                </div>
            </div>
        </div>
    );
};

export default Sponsors;
