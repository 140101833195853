import React, { useState, useEffect } from "react"
import { buildTitle, PageTitle } from "../components/PageTitle"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import CardSmall from "../components/CardSmall"

const Entrades = () => {
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  const [espectacles, setEspectacles] = useState(null)

  useEffect(() => {
    fetch(process.env.REACT_APP_JF_API_HOST + "/home")
      .then((response) => response.json())
      .then((json) => {
        setEspectacles(json.espectacles)
      })
      .catch((error) => console.error(error))
  }, [])

  return (
    <div>
      <PageTitle title={buildTitle("Venda d'entrades")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-4xl lg:text-6xl tracking-wide text-blue font-brik px-6'>Venda d'entrades</h1>
      </div>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
        <h2 className='text-3xl tracking-wide text-blue font-brik'>online</h2>
        <div className='grid grid-cols-1 gap-6 lg:grid-cols-3 justify-start'>
        {espectacles ? (
  espectacles
    .filter(project => project.cta.status !== "HIDDEN")
    .map((project, index) => (
      <CardSmall
        key={index}
        url={project.url}
        id={project.id}
        title={project.title}
        text1={project.subtitle1 || ""}
        text2={project.subtitle2 || ""}
        button={project.cta}
        backgroundColor={project.background_color} // Pasamos el color de fondo
      />
    ))
) : (
  <p>Carregant dades...</p>
)}
        </div>
      </div>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
        <h2 className='text-3xl tracking-wide text-blue font-brik pb-6'>A taquilla</h2>
        <div className='flex flex-col lg:flex-row justify-start'>
          <section className='pb-8 lg:pr-32'>
            <h3 className='text-xl font-bold text-blue uppercase'>Dies previs a la funció</h3>
            <a
              href='https://www.google.es/maps/place/Teatre+Principal+de+Sabadell/@41.5455431,2.1054186,17z/data=!3m1!4b1!4m6!3m5!1s0x12a49501a07fe713:0x5c66a337d761f0f0!8m2!3d41.5455391!4d2.1079935!16s%2Fg%2F1235w58m?hl=ca&entry=ttu'
              className='text-xl font-mori underline'
            >
              Teatre Principal
            </a>
            <p className='font-mori'>C/ Sant Pau, 6</p>
            <p className='font-mori'>Dimecres i dijous, de 18h a 20h</p>
            <p className='font-mori'>Divendres i dissabtes, de 11h a 13h i de 18h a 20h</p>
          </section>
          <section className='pb-8 lg:pr-32'>
            <h3 className='text-xl font-bold text-blue uppercase'>Abans de la funció</h3>
            <a
              href='https://www.google.es/maps/place/Teatre+La+Far%C3%A0ndula/@41.5455431,2.1054186,17z/data=!4m6!3m5!1s0x12a49506b4e712db:0xb03767339f9a990b!8m2!3d41.5460189!4d2.1110086!16s%2Fg%2F120pgvwt?hl=ca&entry=ttu'
              className='text-xl font-mori underline'
            >
              Teatre La Faràndula
            </a>
            <p className='font-mori'>C/ República, 33</p>
            <p className='font-mori'>Dissabtes i diumenges, 1h abans de la funció</p>
          </section>
        </div>
      </div>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
      <h2 className='text-3xl tracking-wide text-blue font-brik pb-6 uppercase'>TARIFES GENERALS<br/>I DESCOMPTES</h2>

{/* Grid para los títulos y los precios */}
<div className="lg:w-1/3">
  {/* Grid para los títulos y los precios */}
  <div className="grid grid-cols-5 text-lg font-bold pb-2">
    <span className="col-span-3"></span>
    <span className="text-center col-span-1">Platea</span>
    <span className="text-center col-span-1">Pis</span>
  </div>

  {/* Entrada General */}
  <div className='grid grid-cols-5 text-lg font-bold py-1'>
    <span className="col-span-3">Entrada General</span>
    <span className='text-center col-span-1'>15€</span>
    <span className='text-center col-span-1'>12€</span>
  </div>

  {/* Entrada Especial */}
  <div className='grid grid-cols-5 text-base py-1 opacity-75'>
    <span className="col-span-3">Entrada amb descompte (20%)</span>
    <span className='text-center col-span-1 '>12€</span>
    <span className='text-center col-span-1'>9,6€</span>
  </div>

  {/* Descripción */}
  <p className='text-sm font-mori opacity-75'>
    *Famílies nombroses i monoparentals <br />
    *Grups (+15 persones)
  </p>
</div>
      </div>
      <div className='w-full bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
        <button
          onClick={() => setIsInfoOpen(!isInfoOpen)}
          className='w-full text-3xl tracking-wide text-blue font-brik pb-6 flex justify-between items-center'
        >
          <span className='uppercase'>Més informació</span>
          <span className={`transform transition-transform ${isInfoOpen ? "rotate-180" : "rotate-0"}`}>
            <svg width='40' height='25' viewBox='0 0 40 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1 1L20.0001 24L39 1.48936' stroke='#003E5F' />
            </svg>
          </span>
        </button>
        {isInfoOpen && (
          <div className='text-blue px-6'>
            <p className='font-mori uppercase'>
              · <b>Reserves de grup</b>
            </p>
            <p className='font-mori'>
              Les reserves de grup només es poden gestionar online enviant un correu electrònic a{" "}
              <a href='mailto:comunicacio@joventutdelafarandula.cat' className='underline'>
                comunicacio@joventutdelafarandula.cat
              </a>
              ,{" "}
              <a href='mailto:taquilla@ajsabadell.cat' className='underline'>
                taquilla@ajsabadell.cat
              </a>{" "}
              o de manera presencial a la taquilla del teatre.
            </p>
            <p className='font-mori mt-4 uppercase'>
              · <b>Menors de 2 anys</b>
            </p>
            <p className='font-mori'>
              Els menors de 2 anys poden assistir gratuïtament als espectacles de la Joventut de la Faràndula sempre que no ocupin un seient propi i es quedin a
              la falda dels seus acompanyants.
            </p>
            <p className='font-mori mt-4 uppercase'>
              · <b>Mobilitat reduïda</b>
            </p>
            <p className='font-mori'>
              Si necessiteu entrades per a la zona de mobilitat reduïda, podeu fer una sol·licitud enviant un correu electrònic a{" "}
              <a href='mailto:taquilla@ajsabadell.cat' className='underline'>
                taquilla@ajsabadell.cat
              </a>
              .
            </p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Entrades
