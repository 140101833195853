import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer";

const Loading = () => {
    return (
        <div>
            {/* Navbar */}
            <Navbar/>
            <div className='border-b border-l border-r border-blu'
                 style={{padding: "30px", minHeight: "150px"}}>Carregant...
            </div>
            <Footer/>
        </div>
    )
}

export default Loading
