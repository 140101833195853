import React, { useState } from "react"

const FitxaArtistica = ({ casting }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='w-full bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
      <button onClick={() => setIsOpen(!isOpen)} className='w-full text-3xl tracking-wide text-blue font-brik pb-6 flex justify-between items-center'>
        <span className='uppercase'>Fitxa Artística</span>
        <span className={`transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"}`}>
          <svg width='40' height='25' viewBox='0 0 40 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L20.0001 24L39 1.48936' stroke='#003E5F' />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div className='text-blue px-6'>
          <div className='font-mori tracking-wide grid grid-cols-2 lg:grid-cols-3 gap-4'>
          {casting && casting.map((item, index) => {
        if (item.type === "TITLE") {
          return (
            <h2 key={index} className='col-span-2 lg:col-span-3 text-2xl font-bold text-blue uppercase border-t pt-4'>
              {item.name}
            </h2>
          )
        }
        if (item.type === "SUBTITLE") {
          return (
            <h3 key={index} className='col-span-2 lg:col-span-3 text-xl font-semibold text-blue pt-2'>
              {item.name}
            </h3>
          )
        }
        return (
          <p key={index} className='text-base text-gray-700'>
            {item.role ? (
              <span className='font-bold'>{item.role}: </span>
            ) : null}
            {item.name}
          </p>
        )
      })}
          </div>
        </div>
      )}
    </div>
  )
}

export default FitxaArtistica
