import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import Navbar from "../components/Navbar"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"
import Loading from "./Loading";
import Notfound from "./NotFound";
import {buildTitle, PageTitle} from "../components/PageTitle";
import ProjectDetailComponent from "../components/ProjectDetailComponent";

const ProjectDetail = () => {
    const [project, setProject] = useState(null)
    const [notFound, setNotFound] = useState(null)

    const {season, slug} = useParams()

    useEffect(() => {
        fetch(process.env.REACT_APP_JF_API_HOST + "/espectacle/" + season + "/" + slug)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status + " Failed Fetch ");
                }
            }).then((json) => setProject(json))
            .catch((error) => {
                console.error("ERROR: " + error)
                setNotFound(true)
            })
    }, [slug])
    if (notFound === true) {
        return <Notfound/>
    }

    return (
        <div>
            <Navbar/>
            {project ? (
                    <div>
                        <PageTitle title={buildTitle(project.title)}/>
                        <ProjectDetailComponent project={project}/>
                    </ div>
                ) :
                (
                    <Loading/>
                )
            }
            <Newsletter/>
            <Footer/>
        </div>
    )
}

export default ProjectDetail
