import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Newsletter from "../components/Newsletter"
import { buildTitle, PageTitle } from "../components/PageTitle"

const TenmporadesAnteriors = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Temporades anteriors")} />
      <Navbar />
      {/* Título de la página */}
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-4xl lg:text-6xl tracking-wide text-blue font-brik px-6'>Temporades Anteriors</h1>
      </div>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h2 className='text-xl lg:text-xl tracking-wide text-blue font-mori px-6'>Pàgina en construcció.</h2>
      </div>
      <Newsletter />
      <Footer />
    </div>
  )
}

export default TenmporadesAnteriors
