import React from "react"
import "./App.css"
import Home from "./pages/Home"
import Contacte from "./pages/Contacte"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Entrades from "./pages/Entrades"
import ComArribar from "./pages/ComArribar"
import Historia from "./pages/Historia"
import Tti from "./pages/Tti"
import ProjectDetail from "./pages/ProjectDetail"
import QuiSom from "./pages/QuiSom"
import Tta from "./pages/Tta"
import AulaJove from "./pages/AulaJove"
import TenmporadesAnteriors from "./pages/TemporadesAnteriors"
import ApostesEnEscena from "./pages/ApostesEnEscena"
import Notfound from "./pages/NotFound"
import TemporadaActual from "./pages/TemporadaActual"
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize('UA-3033056-2');

  return (
    <BrowserRouter>
      <main className='bg-gray min-h-screen h-auto p-6'>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/entitat/contacta' element={<Contacte />} />
          <Route path='/Programacio' element={<Home />} />
          <Route path='/venda-entrades' element={<Entrades />} />
          <Route path='/com-arribar' element={<ComArribar />} />
          <Route path='/entitat/historia' element={<Historia />} />
          <Route path='/entitat/qui-som' element={<QuiSom />} />
          <Route path='/formacio/teatre-infants-tti' element={<Tti />} />
          <Route path='/formacio/teatre-adults-tta' element={<Tta />} />
          <Route path='/formacio/aula-jove' element={<AulaJove />} />
          <Route path='/programacio/temporades-anteriors' element={<TenmporadesAnteriors />} />
          <Route path='/programacio/apostes-escena' element={<ApostesEnEscena />} />
          <Route path='/temporada/:season' element={<TemporadaActual />} />
          <Route path='/temporada/:season/:slug' element={<ProjectDetail />} />
          <Route path='*' element={<Notfound />} />

        </Routes>
      </main>
    </BrowserRouter>
  )
}

export default App
