import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { buildTitle, PageTitle } from "../components/PageTitle"

const QuiSom = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Qui som")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-4xl lg:text-6xl text-blue font-brik px-6 uppercase'>Qui som?</h1>
      </div>
      <div className='bg-gray border-r border-l border-b border-blue py-4 px-0 text-blue px-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>La Joventut de la Faràndula</h2>
            <p className='font-mori'>
              Joventut de la Faràndula és una associació cultural sense ànim de lucre, fundada l'any 1947 per membres de la Congregació Mariana, components del
              grup teatral "la Faràndula" i altres persones preocupades per la formació i el lleure dels infants.
            </p>
            <p className='font-mori'>
              L’any 1956, es va edificar i posar en marxa el Teatre La Faràndula de Sabadell, actualment convertit en teatre municipal.
            </p>
            <p className='font-mori'>
              Si en vols saber més, prem{" "}
              <a href='https://www.joventutdelafarandula.cat/entitat/historia' className='underline text-blue'>
                Història
              </a>
            </p>
          </div>
          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/joventutdelafarandula.jpg' alt='Teatro' className='w-full h-auto rounded' />
          </div>
          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/teatreperinfants.jpg' alt='Infants' className='w-full h-auto rounded' />
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>El teatre per a infants</h2>
            <p className='font-mori'>
              El teatre infantil ha estat, des de la fundació de l'Entitat, la fita principal de tots els seus membres i l'activitat preferent determinada pels
              seus estatuts. En aquest sentit, s'ha mantingut una Programació Estable de Teatre Infantil durant més de cinquanta anys.
            </p>
            <p className='font-mori'>
              A més, durant 19 anys es va organitzar el Concurs Nacional d'Obres per a Infants amb la intenció de nodrir de nous textos el repertori de
              l'entitat.
            </p>
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>La temporada estable</h2>
            <p className='font-mori'>
              És la programació de la temporada al Teatre Municipal La Faràndula de Sabadell. Són espectacles destinats principalment a un públic infantil,
              sense descuidar als adults que els acompanyen. A vegades, aquestes obres també són representades a teatres dels barris de Sabadell, o bé són
              portades per diverses poblacions de Catalunya.
            </p>
            <p className='font-mori'>
              Si en vols saber més, consulta la{" "}
              <a href='https://www.joventutdelafarandula.cat/' className='underline text-blue'>
                Temporada estable
              </a>
            </p>
          </div>
          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/temporada.jpg' alt='Temporada Estable' className='w-full h-auto rounded' />
          </div>

          {/* Nuevas secciones añadidas */}
          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/recurs1.jpg' alt='Formació Teatral' className='w-full h-auto rounded' />
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>Formació Teatral</h2>
            <p className='font-mori'>
              És un complement molt valuós que l'Entitat posa a l'abast de tots els seus components, per tal de millorar la seva formació teatral -i humana-, i
              afavorir la consolidació del col·lectiu que formen els farandulers:
              <ul>
                <br></br>
                <li>
                <a href='https://www.joventutdelafarandula.cat/formacio/tti' className='underline text-blue'>
                Taller de Teatre Infantil (TTi): Formació per infants de 6 a 13 anys.
              </a>
              </li>
              <br></br>
                <li>
                <a href='https://www.joventutdelafarandula.cat/formacio/aula-jove' className='underline text-blue'>
                Aula Jove de Teatre: Formació per a joves de 14 a 17 anys.
              </a>
              </li>
              <br></br>
                <li>
                <a href='https://www.joventutdelafarandula.cat/formacio/tta' className='underline text-blue'>
                Taller de Teatre per Adults: Formació per adults a partir de 18 anys.
              </a>
              </li>              
              </ul>
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>L'Entitat</h2>
            <p className='font-mori'>
              La Joventut de la Faràndula compta amb quasi 200 membres actius d'ambdós sexes i de diverses edats, els quals s'ocupen, desinteressadament, de
              totes les tasques pròpies de l'activitat teatral: Direcció, interpretació, formació, cant, dansa, escenografia, vestuari, il·luminació, so,
              etcètera, a més de les responsabilitats de govern de l'Entitat, gestió, administració i secretaria.
            </p>
          </div>
          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/entitat.jpg' alt='L’Entitat' className='w-full h-auto rounded' />
          </div>

          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/quisom/pastorets.jpg' alt='Els Pastorets' className='w-full h-auto rounded' />
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl text-blue font-brik pb-4 uppercase'>Els Pastorets</h2>
            <p className='font-mori'>
              Els Pastorets, en la versió de J.M. Folch i Torres, són potser l'element teatral més important de l'Entitat, degut a la seva repetida
              representació, Nadal rere Nadal i per l’orgull que representa pels farandulers poder-los fer. Es posa especial èmfasi en tot el què l'envolta: es
              cuida fins l'últim detall d'escenografia, de vestuari, d'interpretació, de promoció, etc. En aquest afany de maximitzar la qualitat dels
              Pastorets, els més petits de l'entitat, tenen la oportunitat des de fa uns anys, de participar-hi de prop en la seva versió Infantil, on els
              petits es posen a la pell de Lluquet, Satanàs o Jeremies, adquirint un rodatge, que els pot ser útil més endavant per participar als altres
              Pastorets. Si en vols saber més, clica Els Pastorets
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default QuiSom
