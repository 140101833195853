import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { buildTitle, PageTitle } from "../components/PageTitle";

const ApostesEnEscena = () => {
  const shows = [
    {
      title: 'BURUNDANGA',
      location: 'Auditori Pere Quart',
      address: 'Rambla, 69. Sabadell',
      dates: '25 d\'octubre de 2024 a les 20h',
      time: '26 d\'octubre de 2024 a les 18h',
    },
    {
      title: 'DESDIBUIXATS',
      location: 'Auditori Pere Quart',
      address: 'Rambla, 69. Sabadell',
      dates: '14 i 15 de febrer de 2025',
      time: '20:30 h',
    },
  ];

  return (
    <div>
      <PageTitle title={buildTitle('Apostes en escena')} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-4xl lg:text-6xl text-blue font-brik px-6 uppercase'>Apostes en escena</h1>
      </div>
      <div className='bg-gray border-r border-l border-blue py-4 px-6 text-blue grid gap-6 grid-cols-1 md:grid-cols-2'>
        {shows.map((show, index) => (
          <div
            key={index}
            className='bg-white border border-blue p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200'
          >
            <h2 className='text-4xl font-brik text-blue mb-2'>{show.title}</h2>
            <p className='font-mori text-gray-700'>{show.location}</p>
            <p className='font-mori text-gray-700'>{show.address}</p>
            <p className='font-mori text-gray-700'>{show.dates}</p>
            <p className='font-mori text-gray-700'>{show.time}</p>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ApostesEnEscena;
