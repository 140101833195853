import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";

const PageTitle = ({ title }) => {
  const location = useLocation()

  useEffect(() => {
    document.title = title
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: title
      })
    }
  }, [location, title])

  return null
}

function buildTitle(text) {
  if (typeof text !== "string" || text.length === 0) {
    return process.env.REACT_APP_MAIN_TITLE
  }
  return text + " - " + process.env.REACT_APP_MAIN_TITLE
}

export { PageTitle, buildTitle }
