import React from "react"
import { Link } from "react-router-dom"

function isActive(button) {
  return typeof button.status === "string" && button.status != "HIDDEN" && button.text.length > 0
}

const CardSmall = ({url, title, text1, text2, button, backgroundColor }) => {
  return (
    <Link
      to={url}
      className='p-4 w-full  mt-5 overflow-hidden rounded-2xl shadow-lg'
      style={{ backgroundColor: backgroundColor }}
    >
      <div className='p-4'>
        <h2 className='mb-2 text-3xl text-blue font-brik'>{title}</h2>
        <p className='text-lg text-blue font-mori'>{text1}</p>
        {text2 && <p className='text-lg text-blue font-mori mb-4'>{text2}</p>}
        {isActive(button) ? <button className='w-full h-2/3 bg-blue text-gray font-mori text-m py-1 px-2 rounded-full' onClick={() => window.open(button.url, '_blank')} >{button.text}</button> : ""}
      </div>
    </Link>
  )
}

export default CardSmall
