import React from "react"
import { Link } from "react-router-dom"

function isActive(button) {
  return (
    typeof button.status === "string" &&
    button.status === "ACTIVE" &&
    button.text.length > 0
  )
}

function isNotClickable(button) {
  return (
      typeof button.status === "string" &&
      button.status === "INACTIVE" &&
      button.text.length > 0
  )
}

const Card = ({
  url,
  imageUrl,
  title,
  text1,
  text2,
  button,
  backgroundColor,
}) => {
  return (
    <Link
      to={url}
      className="p-2 sm:p-4 w-full mt-3 sm:mt-5 rounded-2xl shadow-lg flex flex-col"
      style={{ backgroundColor: backgroundColor }}
    >
      <img
        className="w-full h-auto block object-cover object-center rounded-t-2xl rounded-b-lg"
        src={imageUrl}
        alt={title}
      />
      <div className="flex flex-col flex-grow p-4">
        <div className="flex-grow">
          <h2 className="mb-2 text-3xl text-blue font-brik">{title}</h2>
          <p className="text-lg text-blue font-mori">{text1}</p>
          {text2 && (
            <p className="text-lg text-blue font-mori mb-4">{text2}</p>
          )}
        </div>
        {isActive(button) && (
          <button
            className="w-full bg-blue text-gray font-mori text-m py-1 px-2 rounded-full"
            onClick={() => window.open(button.url, "_blank")}
          >
            {button.text}
          </button>
        )}
        {isNotClickable(button) && (
            <button className="w-full bg-blue text-gray font-mori text-m py-1 px-2 rounded-full">
              {button.text}
            </button>
        )}
      </div>
    </Link>
  )
}

export default Card
