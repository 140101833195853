import React, {useEffect, useState} from "react"
import {buildTitle, PageTitle} from "../components/PageTitle"
import Navbar from "../components/Navbar"
import Card from "../components/Card"
import Slider from "../components/Slider"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"
import {useParams} from "react-router-dom";

const TemporadaActual = () => {
    const [espectacles, setEspectacles] = useState(null)
    const [temporada, setTemporada] = useState(null)
    const{season}=useParams()
    useEffect(() => {
        fetch(process.env.REACT_APP_JF_API_HOST + "/espectacles/" + season)
            .then((response) => response.json())
            .then((json) => {
                setEspectacles(json[0].espectacles)
                setTemporada(json[0].temporada)
            })
            .catch((error) => console.error(error))
    }, [])

    return (
        <div>
      <PageTitle title={buildTitle("Temporada " + temporada )} />
      <Navbar/>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-4xl lg:text-6xl text-blue font-brik px-6 uppercase'>Temporada {temporada}</h1>
      </div>
            <div className='border-b border-l border-r border-blu p-12 flex flex-col justify-center items-center'>
                <div className='min-h-full sm:px-12 lg:px-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-12'>
                    {espectacles ? (
                        espectacles.map((project, index) => (
                            <Card
                                key={index}
                                url={project.url}
                                id={project.id}
                                imageUrl={project.image_url}
                                title={project.title}
                                text1={project.subtitle1 || ""}
                                text2={project.subtitle2 || ""}
                                button={project.cta}
                                backgroundColor={project.background_color} // Pasamos el color de fondo
                            />
                        ))
                    ) : (
                        <p style={{minHeight: "150px"}}>Carregant dades...</p>
                    )}
                </div>
            </div>

            <Newsletter/>
            <Footer/>
        </div>
    )
}

export default TemporadaActual
