import React from "react";

const slideImages = [
  {
    url: "https://www.joventutdelafarandula.cat/img/slider/Joventut_de_la_Farandula_slider.jpg",
  },
];

const Slider = () => {
  return (
    <div className="relative">
      <div className="h-[400px] w-full relative">
        <div
          className="flex items-center justify-center bg-cover bg-center h-full"
          style={{ backgroundImage: `url(${slideImages[0].url})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4">
            <h2 className="text-gray font-brik drop-shadow-md text-3xl lg:text-5xl text-center mb-4">
              PERQUÈ ENS AGRADA EL TEATRE
            </h2>
            <button
              onClick={() => window.open("https://youtu.be/DMRKSpsFQxY", "_blank")}
              className="uppercase mt-4 bg-blue drop-shadow-md hover:drop-shadow-2xl text-gray font-mori py-2 px-6 rounded-full"
            >
              Coneix la nostra programació
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
